.signup_form .formio-component-button {
    font-family: 'Noto Sans Medium';
    background-color: inherit;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 0px;
    margin: 0px;
    text-align: center;
  }

/* .Signup .vahan {
    margin-bottom: 50px;
} */
.modal-cookie {
  position: fixed; /* Stay in place */
  z-index: 20; /* Sit on top */
  left: 0;
  text-align: center;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-cookie-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  margin:auto;
  margin-top: 20%;
  width: 80%;
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 400px) {
  .modal-cookie-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    margin:auto;
    margin-top: 20%;
  border-radius: 10px;
  width: 80%;
  }
  
}

@media screen and (min-width: 400px) {
  .modal-cookie-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    margin:auto;
  border-radius: 10px;
  margin-top: 20%;
    width: 40%;
  }
  
}