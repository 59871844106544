@font-face {
    font-family: 'Noto Sans Light';
    src: url('./../fonts/NotoSans-ExtraLight.ttf');
  }
.data-table{
    text-align: center;
  border-collapse: collapse;
  /* border: 2px solid #FF712D; */
  border-radius: 5px;
  width: calc(70% + 50px);
}
.data-table-content {
  width: 100%;
  border-collapse: collapse;
}
.padding-class {
  padding-left: 20%;
}
.data-td, .data-th {
border: 2px solid #FF712D;
  padding: 8px;
}

.data-body .data-tr:nth-child(even){
  background-color: #f2f2f2;
}
.data-th {
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  /* background-color: #4CAF50; */
}
button.viewLocations {
  color: white;
  background: #D34612;
  padding: 8px 16px;
  width:100%;
  margin-top: 1rem;
  font-weight: 700;
}
button.moreLocalities {
  color: #D34612;
  padding: 8px 16px;
  margin-top: 1rem;
  font-weight: 700;
  border: 2px solid #D34612;
  width:95%;
  margin: 10px;
}
.jobs-page h4 {
  text-align: left;
  padding: 1rem 0;
  margin-left: 3%;
  font-size: 1.2rem;
  background: #ffffff;
  color: #151515;
  font-weight: 700;
}
.jobs-page {
  font-family: 'Nunito Sans', sans-serif !important;
  background: #ffffff;
}
.jobs-page .detail-element-body{
  display: inline-block;
  padding: 1rem;
  width: 50%;
}
.jobs-page .Dropdown-control {
  display: inline-block !important;
  text-transform: none !important;
  letter-spacing: 0.5px;
  font-weight: 600 !important;
}
.jobs-page .allCities {
  color: #D34612;
  font-weight: 700;
  text-align: left;
  padding: 1.5rem;
  letter-spacing: 0.3px;
  /* background: #F0F2F5; */
}
.jobs-page h6 {
  font-weight: 700;
}
.jobs-page h6.content {
  font-size: 0.9rem;
  font-weight: 100;
}
.jobs-page .row .col {
  background: #FAFAFA;
  padding: 1.5rem;
}
.jobs-page .row {
  margin-right: 0 !important;
}
.jobs-page .cityHeading, .jobs-page .row {
  font-weight: 100;
  font-size: 0.9rem;
}
.jobs-page .cityHeading b.bold, .jobs-page .row b.bold {
  font-weight: 600;
  color: #525252;
}
div.form_control.job-demands-table {
  margin-bottom: 60px;
}
.jobs-page .tab {
  width: 100%;
}
.header-text .requirements-title {
  font-weight: 100;
  color: #666565;
}
.jobs-page .header .header-search {
  display: flex;
  visibility: visible;
}
@media screen and (max-width: 500px) {
  .padding-class {
    padding-left: 5%;
  }
  .jobs-page .detail-element-body{
    width: 100%;
  }
  .jobs-section.active-tab{
    border-bottom: 2px solid #D34612 !important;
    color: #D34612 !important;
  }
  .jobs-page h4 {
    font-size: 1rem;
  }
  .header-text .requirements-title {
    font-weight: 700;
  }
}