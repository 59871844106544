.upload-leads{
    width: 90%;
    height: 40px;
    background: #FFEAE0;
    border: 1px solid #D34612;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    margin: 0 auto;
}

.upload-leads-label{
    color: rgb(211, 70, 18);
    text-align: center;
    width: inherit;
}
.hp_slide{
    width: 100%;
    background: white;
    height: 25px;
}
.hp_range{
    width:0;
    background: #ff9393;
    height: 50px;
}
.file-uploaded{
    background: #FAFAFA;
    border: 1px dashed #595959;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    width: 90%;
    margin: 10px auto;
    height: 40px;
    padding: 7px 15px;
    color: #595959;
    font-weight: bold;
    display: flex;
    flex-direction: row;
}

.download-template{
    background: #F5F5F5;
    border: 1px dashed #D9D9D9;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
    border-radius: 2px;
    width: 90%;
    margin: 10px;
    height: 40px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.25);
    font-style: none;
    text-decoration: none;
    padding: 7px;
}

.download-template:hover{
    color: rgba(0, 0, 0, 0.25);
}

.client-error{
    margin: 5px 30px;
    text-align: left;
    color: red;
    font-weight: 600;
}

.submit-button {
    width: 90%;
    background-color: rgb(211, 70, 18);
    color: white;
    margin: 0px 5%;
    padding: 6px;
    bottom: 23px;
    position: fixed;
}

.view-leads{
    text-align: center;
    background: rgb(211, 70, 18);
    border-radius: 2px;
    color: white;
    margin: 0px 35%;
    cursor: pointer;
}

.noteHeading{
    font-weight: bold;
    padding: 10px;
    color: revert;
}