.bg_gray {
    padding: 15px;
    background-color: lightgray;
    position: relative;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
    transition: .5s;
}

.bg_gray:hover {
    background-color: #FF712D;
}

.AccountPage{
    overflow-x: hidden; 
}

.AccountPage, .row{
    padding: 10px;
    /* margin-left: auto; */
    text-align: center;
}