
@media screen and (max-width: 800px) {
	.nav-wrapper-desktop{
		display: none;
	}

	.navigation-wrapper {
		width: 100%;
		height: 75px;
		display: flex;
		justify-content: center;
		background-color: #ffffff;
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
		font-family: "Noto Sans Medium";
	}

	.navigation-wrapper {
		width: 100%;
		height: 75px;
		display: flex;
		justify-content: center;
		background-color: #ffffff;
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	}

	.navigation-menu {
		height: 100%;
		width: 90px;
		cursor: pointer;
	}

	.navigation-text {
		color: #3d3c3a;
		font-weight: bold;
		font-size: 12px;
		letter-spacing: 0;
		line-height: 17px;
		text-align: center;
	}

	.active-navigation-tab {
		font-size: 14px;
		font-family: 'Gilroy ExtraBold';
	}

	.nav-icon {
		padding-top: 25px;
	}
}

@media screen and (min-width: 800px) {
	.nav-wrapper-desktop{
		display: block;
		position: fixed;
		top:0;
		width: 200px;
		height: 100%;
		justify-content: left;
		background-color: #ffffff;
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
		border-right: 2px solid rgba(0, 0, 0, 0.15);
	}

	.navigation-menu {
		cursor: pointer;
		margin-top: 17px;
		text-align: left;
		margin-left: 12px;
	}

	.navigation-wrapper {
		display: none;
	}

	.navigation-text {
		color: #3d3c3a;
		font-weight: bold;
		font-size: 15px;
		letter-spacing: 0;
		line-height: 17px;
		text-align: center;
	}

	.active-navigation-tab {
		font-size: 14px;
		font-family: 'Gilroy ExtraBold';
	}

	.nav-icon {
		padding-top: 25px;
	}
	
	#logout-footer-icon{
		bottom: 0;
		position: fixed;
		margin-bottom: 20px;
	}
}

