.header-text{
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    width: 53%;
    text-align: left;
}

.header-download{
    text-align: right;
    padding: 2px 0px;
    margin-left: 10px;
}

.download-button{
    height: 28px;
    background: #FFF7F4;
    border: 1px solid #D34612;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #D34612;
}

.main-container{
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 60px;
}

.total-earnings{
    display: flex;
    flex-direction: column;
    background: white;
    padding: 1.5% 5%;
}

.total-earning-header{
    display: flex;
    flex-direction: row;
    padding: 3% 0%;
}

.total-earning-header-text{
    width: 50%;
    text-align: left;
    font-weight: bold;
}

.total-earning-header-date{
    width: 50%;
    text-align: right;

}

.horizontal-bar{
    /* height: 60px; */
    padding: 12px 0px;
}

.horizontal-bar-text{
    display: flex;
    flex-direction: row;
    padding: 1% 0%;
}

.paid-amount-container{
    width: 50%;
    text-align: left;
}

.unpaid-amount-container{
    width: 50%;
    text-align: right;
}
.percentage{
    margin-left: 23px;
}

.single-card{
    display: flex;
    border-radius: 2px;
    padding: 3% 2%;
    flex-direction: row;
    margin: 5px 0;
    color: white;
    font-size: 15px;
}

.card-left{
    width: 65%;
    text-align: left;
}

.card-left-overall-performance{
    width: 55%;
    text-align: left;
    padding: 12px 0;
}

.card-left-top{
    font-weight: bold;
}

.card-right{
    width: 35%;
    text-align: right;
    padding: 12px 0px;
    font-weight: bold;
}

.report-right-arrow{
    padding: 12px 0;
    width: 10%;
}

.progressBar{
    width: 100%;
    height: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0d6efd;
    transition: width .6s ease;
}
  
  progress::-moz-progress-bar {
    background: lightcolor;
  }
  
  progress::-webkit-progress-value {
    background: #52C41A;
  }
  
  progress::-webkit-progress-bar {
    background: #D48806;
  }
  



.overall-performance{
    background-color: white;
    padding: 1.5% 5%;
}

.overal-performance-heading{
    text-align: left;
    font-weight: bold;
    padding: 3% 0%;
}

.chart-container{
    display: flex;
    flex-direction: row;
    margin: 15px 0;
}

.chart{
    width: 50%;
    margin: auto;
}

.chart-details{
    width: 50%;
    padding: 10% 7%;
}

.single-label-container{
    display: flex;
    flex-direction: row;
    padding: 2px 0;
}




.table-container{
    background-color: white;
    padding: 1px 5%;
    padding-bottom: 80px;
}

.table-layout{
    margin: 20px 0px;
}

.table-heading{
    background: #FAFAFA;
    border: 1px solid #F0F0F0;
    text-align: left;
    padding: 10px 10px;
    font-size: 14px;
    width: 1%;
    font-weight: bold;
    color: #666565;
}



.single-cell{
    background: white;
    border: 1px solid #F0F0F0;
    text-align: left;
    padding: 10px 10px;
    font-size: 14px;
}

.total-container{
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.total-candidates{
    font-size: 20px;
    padding-bottom: 10px;
    font-weight: bold;
}



@media screen and (min-width: 840px) {
    .small-size-screen{
        display: none;
        visibility: hidden;
    }

    .big-screen{
        display: block;
        visibility: visible;
    }

    .date-range{
        position: relative;
        display: inline-block;
        margin-left: 1%;
    }

    .main-container{
        /* display: flex; */
        /* flex-direction: row; */
        background-color: white;
        padding: 10px 5%;
        padding-right: 3%;
    }

    .header-download{
        text-align: right;
        padding: 2px 0px;
        margin-left: 20px;
    }



    .space-needed{
        height: 20px;
    }
    .visible{
        width: 80%;
    }
    .filter-set-container{
        margin-left: -1%;
    }
    .total-earnings{
        border: 1px solid #F4F4F4;
        margin-right: 10px;
    }
    .overall-performance{
        border: 1px solid #F4F4F4;
        margin-left: 20%;
    }
}

@media screen and (min-height: 500px) and (max-height: 8400px) {
    .small-size-screen{
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 500px) {
    .small-size-screen{
        display: block;
        visibility: visible;
    }
    .date-range{
        margin-left: 5%;
    }
    .big-screen{
        display: none;
        visibility: hidden;
    }
    .chart-details{
        width: 50%;
        padding: 0px;
    }

    .total-candidates{
        font-size: 15px;
        padding-bottom: 10px;
        font-weight: bold;
    }
}

.leads-wrapper {
    background-color: #FFFFFF;
    padding: 0px 5%;
    padding-bottom: 16%;
    padding-top: 10px;
}

@media screen and (min-width: 700px) and (max-width: 1025px) {
	.leads-wrapper {
        background-color: #FFFFFF;
        padding: 0px 5%;
        padding-bottom: 16%;
	}
}

