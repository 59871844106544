
  
  
  
  /*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
	.modal.left .modal-dialog,
	.modal.right .modal-dialog {
		position: fixed;
		margin: auto;
		width: 320px;
		height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

	.modal.left .modal-content,
	.modal.right .modal-content {
		height: 100%;
		overflow-y: auto;
	}
	
	.modal.left .modal-body,
	.modal.right .modal-body {
		padding: 15px 15px 80px;
	}

/*Left*/
	.modal.left.fade .modal-dialog{
		left: -320px;
		-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, left 0.3s ease-out;
		        transition: opacity 0.3s linear, left 0.3s ease-out;
	}
	
	.modal.left.fade.in .modal-dialog{
		left: 0;
	}
        
/*Right*/
	.modal.right.fade .modal-dialog {
		right: -320px;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	
	.modal.right.fade.in .modal-dialog {
		right: 0;
	}

/* ----- MODAL STYLE ----- */
	.modal-content {
		border-radius: 0;
		border: none;
	}

	.modal-header {
		border-bottom-color: #EEEEEE;
		background-color: #FAFAFA;
	}

/* ----- v CAN BE DELETED v ----- */
body {
	background-color: #78909C;
}

.demo {
	padding-top: 60px;
	padding-bottom: 110px;
}

.btn-demo {
	margin: 15px;
	padding: 10px 15px;
	border-radius: 0;
	font-size: 16px;
	background-color: #FFFFFF;
}

.btn-demo:focus {
	outline: 0;
}

.demo-footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 15px;
	background-color: #212121;
	text-align: center;
}

.demo-footer > a {
	text-decoration: none;
	font-weight: bold;
	font-size: 16px;
	color: #fff;
}

.blur-bg{
    filter:blur(3px)
}

.input-style-common{
	min-width: 20% !important;
	font-size: 15px;
	background-color: #e0e0e0;
	padding: 1rem 2rem 1rem 1rem !important;
	border: 2px solid #e0e0e0;
	z-index: 1;
	border-radius: 10px;
}

.header-welcome{
    width: 50%;
    display: flex;
    flex-direction: row;
	margin-left: 7px;
}

.icon-right{
    line-height: inherit;
}

.header-search{
    /* width: 50%; */
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    align-items: center;
    margin-left: auto;
    height: 32px;
    display: flex;
    flex-direction: row;
} ::placeholder{
    font-family: 'Nunito Sans', sans-serif;
}

.search-icon-home{
    padding: 0px 5px;
    margin-left: -28px;
    text-align: center;
    align-items: center;
    border-left: 1px solid rgb(133, 133, 133);
    color: rgb(133, 133, 133);
    background: white;
    height: 23px;
}

.search-input,
.search-input-box{
    width: 100%;
    height: 28px;
    font-size: 17px;
    font-family: 'Nunito Sans', sans-serif;
    color: rgba(2, 2, 2, 0.87);
}

.search-input::placeholder{
    color: rgb(133, 133, 133)
}

.welcome{
	text-align: left;
	font-size: 17px;
	color: #595959
}

.mitra-name{
	color: #D34612;
	font-size: 17px;
	text-align: left;
	margin-left: 7px;
	overflow: hidden;
}


.filters-home{
	display: flex;
	flex-direction: row;
}

.date-filter{
	/* padding: ; */
	border: none;
	background-color: white;
}

.Dropdown-control {
	outline: white;
}

.filter-container, .filter-lead-management {
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    text-align: left;
}

.bulk-action-heading{
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	width: 90%;
	text-align: left
}

.bulk-action-container{
    font-family: 'Nunito Sans', sans-serif;
}

.see-all-button {
    width: 90%;
    background-color: rgb(211, 70, 18);
    color: white;
    margin: 0px 5%;
    padding: 6px;
    /* bottom: 23px; */
    /* position: fixed; */
}

.list-scroll-x{
	overflow-x: scroll;
}

/* responsive code */

@media (max-width: 550px) {
	.header-welcome{
		display: flex;
    	flex-direction: column;
	}

	.mitra-name{
		margin-left: 0px;
	}

	.search-input{
		display: none;
	}

	.search-icon-home{
		border-left: solid white 0px;
	}

	.filters-home{
		display: none
	}
}

@media (max-width: 700px) {
	.filter-container{
		display: none;
	}
}

@media (min-height: 635px){
}
